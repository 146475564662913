import {
  ArrowUpRightIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";

export interface Subtitle {
  name: string;
  href: string;
}

export interface HeaderCardItemProps {
  item: {
    name: string;
    href: string;
    subtitle: Subtitle[];
  };
  bgColor?: "green" | "blue" | "violet";
}

export default function HeaderCardItem({
  item,
  bgColor = "green",
}: HeaderCardItemProps) {
  const backgroundColorClass =
    {
      green: "bg-custom-gradient-green",
      blue: "bg-custom-gradient-blue",
      violet: "bg-custom-gradient-violet",
    }[bgColor] || "bg-custom-gradient-green";

  const borderColorClass =
    {
      green: "border-green-600",
      blue: "border-blue-600",
      violet: "border-violet-600",
    }[bgColor] || "border-green-600";

  const txtColorClass =
    {
      green: "text-green-600",
      blue: "text-blue-600",
      violet: "text-violet-600",
    }[bgColor] || "text-green-600";

  return (
    <div
      data-meta="HeaderCardItem"
      key={item.name}
      className={`relative rounded-lg p-6 ${backgroundColorClass}`}
    >
      <Link href={item.href} className="flex items-center justify-between">
        <p
          className={`flex max-w-[155px] text-white font-semibold text-xl leading-8`}
        >
          {item.name}
        </p>
        <p>
          <ArrowUpRightIcon
            className={`${txtColorClass} w-16 border rounded-full ${borderColorClass} p-2`}
          />
        </p>
      </Link>
      <ul>
        {item.subtitle.map((title) => (
          <li key={title.href} className="relative max-w-fit">
            <Link
              href={title.href}
              className="flex items-center text-white transition-all duration-300 ease-in-out group"
            >
              <span className="relative after:absolute after:bottom-0 after:left-0 after:w-0 after:h-[2px] after:bg-gray-300 after:transition-all after:duration-300 group-hover:after:w-full">
                {title.name}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

"use client";

import HeaderCardsList from "@/components/Header/HeaderCards/HeaderCardsList";
import PageNavBlock from "@/components/PageNav/pageNavBlock";
import { Dialog, Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  ArrowUpRightIcon,
  Bars3Icon,
  PhoneIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useRef, useState } from "react";

const menuTitle = [
  {
    id: 1,
    name: "Головна",
    description: "Головна сторніка",
    href: "/",
  },
  {
    id: 2,
    name: "AP School",
    description: "Онлайн навачання у AP School",
    href: "/school",
    priceName: "Ціна",
    hrefPrice: "/school/#price",
    color: "violet" as "violet" | "blue" | "green" | undefined,
    submenu: [
      {
        name: "Дошкільна підготовка",
        description: "",
        href: "/school/doshkilna-osvita",
        subtitle: [],
      },
      {
        name: "Початкова школа",
        description: "",
        href: "/school/pochatkova-shkola",
        subtitle: [],
      },
      {
        name: "Середня та старша школа",
        description: "",
        href: "/school/serednia-starsha-shkola",
        subtitle: [],
      },
      {
        name: "Табори",
        description: "",
        href: "/school/tabori",
        subtitle: [],
      },
      {
        name: "Підготовка до НМТ",
        description: "",
        href: "/school/pidgotovka-do-nmt",
        subtitle: [],
      },
    ],
  },
  {
    id: 3,
    name: "AP University",
    description: "Навчання в Університеті",
    href: "/university",
    priceName: "Ціна",
    hrefPrice: "/university/#price",
    color: "blue" as "violet" | "blue" | "green" | undefined,
    submenu: [
      {
        name: "Все про вступ",
        description: "",
        href: "/university/vstup",
        subtitle: [],
      },
      {
        name: "Навчання за кордоном",
        description: "",
        href: "/university/abroad-education",
        subtitle: [],
      },
      {
        name: "Стажування в Україні",
        description: "",
        href: "/university/stazhuvannia",
        subtitle: [],
      },
      {
        name: "Програма обміну",
        description: "",
        href: "/university/programy-obminu",
        subtitle: [],
      },
      {
        name: "Гранти та стипендії",
        description: "",
        href: "/university/grants-and-schoolarships",
        subtitle: [],
      },
    ],
  },
  {
    id: 4,
    name: "Мовні курси",
    description: "Курси іноземних мов",
    href: "/movni-kursy",
    priceName: "Ціна",
    hrefPrice: "/price",
    color: "green" as "violet" | "blue" | "green" | undefined,
    submenu: [
      {
        name: "Англійська мова",
        description: "",
        href: "/movni-kursy/english",
        subtitle: [
          {
            name: "Англійська для дітей",
            href: "/movni-kursy/english/kids",
          },
          {
            name: "Групове навчання",
            href: "/movni-kursy/english/grupove-navchannia",
          },
          {
            name: "Індивідуальне навчання",
            href: "/movni-kursy/english/indyvidualne-navchannia",
          },
          {
            name: "Підготовка до тестів",
            href: "/movni-kursy/english/tests",
          },
          {
            name: "Підготовка до TOEFL",
            href: "/movni-kursy/english/toefl",
          },
          {
            name: "Підготовка до IELTS",
            href: "/movni-kursy/english/ielts",
          },
          {
            name: "Підготовка до TOEIC",
            href: "/movni-kursy/english/toeic",
          },
          {
            name: "Підготовка до Cambridge ESOL",
            href: "/movni-kursy/english/cambridge-esol",
          },
        ],
      },
      {
        name: "Німецька мова",
        description: "",
        href: "/movni-kursy/deutsch",
        subtitle: [
          {
            name: "Німецька для дітей",
            href: "/movni-kursy/deutsch/kids",
          },
          {
            name: "Групове навчання",
            href: "/movni-kursy/deutsch/grupove-navchannia",
          },
          {
            name: "Індивідуальне навчання",
            href: "/movni-kursy/deutsch/indyvidualne-navchannia",
          },
          {
            name: "Підготовка до тестів",
            href: "/movni-kursy/deutsch/tests",
          },
        ],
      },
      {
        name: "Польська мова",
        description: "",
        href: "/movni-kursy/polski",
        subtitle: [
          {
            name: "Польська для дітей",
            href: "/movni-kursy/polski/kids",
          },
          {
            name: "Групове навчання",
            href: "/movni-kursy/polski/grupove-navchannia",
          },
          {
            name: "Індивідуальне навчання",
            href: "/movni-kursy/polski/indyvidualne-navchannia",
          },
          {
            name: "Підготовка до тестів",
            href: "/movni-kursy/polski/tests",
          },
        ],
      },
      {
        name: "Підготовка до ЄВІ",
        description: "",
        href: "/movni-kursy/evi",
        subtitle: [],
      },
    ],
  },
  {
    id: 5,
    name: "Про нас",
    description: "Навчання в Університеті",
    href: "/about-us",
    color: "green" as "violet" | "blue" | "green" | undefined,
    submenu: [
      {
        name: "Що таке AP Education?",
        description: "",
        href: "/about-us/shcho-take-ap-education",
        subtitle: [],
      },
      {
        name: "Ваша кар’єра з нами",
        description: "",
        href: "/about-us/kariera",
        subtitle: [],
      },
      {
        name: "Стань партнером!",
        description: "",
        href: "/about-us/stan-partnerom",
        subtitle: [],
      },
      {
        name: "Запрошуємо до співпраці",
        description: "",
        href: "/about-us/spivpratsia",
        subtitle: [],
      },
      {
        name: "Блог",
        description: "",
        href: "/bloh",
        subtitle: [],
      },
    ],
  },
  {
    id: 6,
    name: "Контакти",
    description: "Навчання в Університеті",
    href: "/kontakty",
  },
  {
    id: 7,
    name: "Ціни",
    description: "Головна сторніка",
    href: "/price",
  },
];

export interface HeaderCustomProps {
  color?: "violet" | "green" | "blue";
  srcLogo?: string;
  logoLink?: string;
  pageNav?: boolean;
  navList?: Array<{
    name: string;
    href: string;
    current: boolean;
  }>;
}

export default function HeaderCustom({
  color = "green",
  srcLogo = "/assets/icon/LogoRevers.svg",
  logoLink = "/",
  pageNav = false,
  navList,
}: HeaderCustomProps) {
  const backgroundColorClass =
    {
      green: "custom-gradient-green-btn",
      blue: "custom-gradient-blue-btn",
      violet: "custom-gradient-violet-btn",
    }[color] || "custom-gradient-green-btn";

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const path = usePathname(); // Use useRouter to get the current route

  const calculateBackgroundOpacity = () => {
    const scrollThreshold = 300; // Adjust this value for when the color should be fully opaque
    const opacity = Math.min(scrollY / scrollThreshold, 1);
    return `rgba(0, 0, 0, ${opacity * 0.5})`; // Gradually change to grey rgba(245, 245, 247, 1)
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const activePathName = (link: string): Boolean => {
    if (!path) {
      return false;
    }

    const pathName = path.split("/").slice(1);
    const href = link.split("/").slice(1);
    if (href[0]?.length === 0 && pathName[0].length !== 0) {
      return false;
    } else {
      return pathName[0].includes(href[0]);
    }
  };

  return (
    <header
      data-meta="HeaderCustom"
      className="top-0 isolate"
      style={{ backgroundColor: calculateBackgroundOpacity() }}
    >
      <nav
        aria-label="Global"
        className="relative mx-auto flex items-center lg:gap-4 justify-between p-6 lg:px-2 xl:px-8 z-50"
        ref={menuRef}
      >
        <div className="flex">
          {!mobileMenuOpen && (
            <Link href={logoLink} className="-m-1.5 p-1.5">
              <span className="sr-only">App Education</span>
              <Image
                width={100}
                height={38}
                alt=" "
                src={srcLogo}
                className="h-8 w-32 lg:hidden xl:block"
              />
              <Image
                width={100}
                height={38}
                alt=" "
                src={"/assets/icon/AP.svg"}
                className="h-8 w-32 hidden lg:block xl:hidden"
              />
            </Link>
          )}
        </div>
        <div className="flex lg:hidden">
          {!mobileMenuOpen && (
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          )}
        </div>
        <div className="hidden lg:flex lg:justify-center flex-shrink-0 lg:gap-x-4 xl:gap-x-6">
          {menuTitle.map((menuItem) => (
            <HeaderCardsList
              key={menuItem.id}
              menuItem={menuItem}
              styleCss={activePathName(menuItem.href) ? "font-bold" : ""} // Apply bold style if active
            />
          ))}
        </div>
        <div className="hidden lg:flex lg:justify-end lg:items-center lg:gap-x-2">
          <a
            href="tel:+380936707129"
            className="lg:flex lg:gap-x-2 items-center lg:text-xs xl:text-sm font-semibold leading-6 text-white"
          >
            <PhoneIcon className="h-[20px] w-[20px] text-white" />
            +38 (093) 670 71 29
          </a>
          <Link
            href={"https://online.ap.education/Account/Login"}
            className="flex gap-x-4 items-center rounded-full bg-white pl-4 pr-1 py-1 font-semibold border text-gray-900 shadow-lg ring-t-1 hover:bg-gray-50"
          >
            <span className="py-1 lg:text-xs xl:text-base">Увійти</span>
            <div className={`rounded-full ${backgroundColorClass}`}>
              <ArrowUpRightIcon className="text-white lg:h-[16px] lg:w-[16px] xl:h-[20px] xl:w-[20px] m-[4px] xl:m-[6px]" />
            </div>
          </Link>
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        className="lg:hidden"
        as="nav"
      >
        <div
          className="fixed inset-0 z-10 bg-black bg-opacity-50"
          aria-hidden="true"
        />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-20 w-full max-w-md overflow-y-auto bg-black text-white px-6 py-6 sm:max-w-sm">
          <div className="flex items-center justify-between">
            <Link href={logoLink} className="-m-1.5 p-1.5">
              <span className="sr-only">AP Education</span>
              <Image
                width={100}
                height={38}
                alt=" "
                src={srcLogo}
                className="h-8 w-32"
              />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-white"
            >
              <span className="sr-only">Закрити меню</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6">
            <div className="space-y-2">
              {menuTitle.map((item) => (
                <Disclosure key={item.name} as="div">
                  {!item.submenu?.length ? (
                    <Link
                      className="flex w-full items-center justify-between rounded-lg py-2 px-3 text-base font-semibold hover:bg-gray-700"
                      href={item.href}
                    >
                      {item.name}
                    </Link>
                  ) : (
                    <Disclosure.Button className="group flex w-full items-center justify-between rounded-lg py-2 px-3 text-base font-semibold hover:bg-gray-700">
                      {item.submenu?.length && <span>{item.name}</span>}
                      {item.submenu && (
                        <ChevronDownIcon
                          className="h-5 w-5 flex-none"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  )}
                  {item.submenu && (
                    <Disclosure.Panel className="space-y-1">
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold hover:bg-gray-700"
                      >
                        {item.name}
                      </Disclosure.Button>
                      {item.submenu.map((submenuItem) => (
                        <Disclosure.Button
                          key={submenuItem.name}
                          as="a"
                          href={submenuItem.href}
                          className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold hover:bg-gray-700"
                        >
                          {submenuItem.name}
                        </Disclosure.Button>
                      ))}
                      <Disclosure.Button
                        key={item.priceName}
                        as="a"
                        href={item.hrefPrice}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold hover:bg-gray-700"
                      >
                        {item.priceName}
                      </Disclosure.Button>
                    </Disclosure.Panel>
                  )}
                </Disclosure>
              ))}
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {pageNav && navList && (
        <div className="hidde xl:block xl:pb-4">
          <PageNavBlock list={navList} />
        </div>
      )}
    </header>
  );
}

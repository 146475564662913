"use client";
import HeaderCardItem from "@/components/Header/HeaderCards/HeaderCardItem";
import {
  Popover,
  PopoverBackdrop,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import { useRef } from "react";

interface Subtitle {
  name: string;
  href: string;
}

interface SubmenuItem {
  name: string;
  href: string;
  subtitle: Subtitle[];
}

interface MenuItem {
  name: string;
  href: string;
  color?: "violet" | "green" | "blue";
  submenu?: SubmenuItem[];
}

interface HeaderCardsListProps {
  menuItem: MenuItem;
  styleCss?: string;
}

export default function HeaderCardsList({
  menuItem,
  styleCss,
}: HeaderCardsListProps) {
  const itemCount = menuItem.submenu ? menuItem.submenu.length : 1;
  const clampedItemCount = Math.min(itemCount, 5);
  const gridTemplateColumns = `repeat(${clampedItemCount}, minmax(0, 1fr))`;
  const panelRef = useRef<HTMLDivElement>(null);

  return (
    <Popover
      data-meta="HeaderCardsList"
      className={`${
        !menuItem.submenu || menuItem.submenu.length === 0
          ? "flex justify-center items-center"
          : ""
      }`}
    >
      <div className="flex gap-x-2 items-center justify-center">
        <Link
          href={menuItem.href}
          className={`text-white text-center ${styleCss} lg:text-xs xl:text-base`}
        >
          {menuItem.name}
        </Link>
        {menuItem.submenu && menuItem.submenu.length > 0 && (
          <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
            <ChevronDownIcon
              aria-hidden="true"
              className="h-5 w-5 flex-none text-gray-400"
            />
          </PopoverButton>
        )}
      </div>
      <PopoverBackdrop className="absolute inset-0 h-dvh" />
      <PopoverPanel
        ref={panelRef}
        unmount={false}
        className="bg-black/50 absolute inset-x-0 top-0 pt-14 shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div
          className="grid gap-x-4 px-6 py-10"
          style={{ gridTemplateColumns }}
        >
          {!!menuItem.submenu &&
            menuItem.submenu.map((item, index) => (
              <HeaderCardItem
                key={index}
                item={item}
                bgColor={menuItem.color ?? "green"}
              />
            ))}
        </div>
      </PopoverPanel>
    </Popover>
  );
}

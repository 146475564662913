export interface navBlockListProps {
  list: Array<{
    name: string;
    href: string;
    current: boolean;
  }>;
}

export default function PageNavBlock({ list }: navBlockListProps) {
  return (
    <div data-meta="PageNavBlock" className="max-w-7xl mx-auto">
      <div className="hidden lg:block lg:rounded-full">
        <nav
          aria-label="Tabs"
          className="isolate flex rounded-full bg-white/40 shadow"
        >
          {list.map((block, tabIdx) => (
            <a
              key={block.name}
              href={block.href}
              aria-current={block.current ? "page" : undefined}
              className="group cursor-pointer relative min-w-0 flex-1 overflow-hidden px-2 py-2 text-center text-sm font-medium rounded-full hover:bg-black/40"
            >
              <button>
                <span className="text-white">{block.name}</span>
              </button>
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
}

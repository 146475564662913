"use client";
import { ArrowUpRightIcon } from "@heroicons/react/16/solid";
import Image from "next/image";
import Link from "next/link";

const navigation = {
  ap_school: [
    { name: "Дошкільна підготовка", href: "/school/doshkilna-osvita/" },
    { name: "Початкова школа", href: "/school/pochatkova-shkola/" },
    {
      name: "Середня та старша школа",
      href: "/school/serednia-starsha-shkola/",
    },
    { name: "Табори", href: "/school/tabori/" },
    { name: "Підготовка до НМТ", href: "/school/pidgotovka-do-nmt/" },
  ],
  ap_university: [
    { name: "Все про вступ", href: "/university/vstup/" },
    { name: "Навчання за кордоном", href: "/university/abroad-education/" },
    { name: "Стажування в Україні", href: "/university/stazhuvannia/" },
    { name: "Програма обміну", href: "/university/programy-obminu/" },
    {
      name: "Гранти та стипендії",
      href: "/university/grants-and-schoolarships/",
    },
  ],
  language: [
    { name: "Англійська мова", href: "/movni-kursy/english/" },
    { name: "Німецька мова", href: "/movni-kursy/deutsch/" },
    { name: "Польська мова", href: "/movni-kursy/polski/" },
  ],
  about: [
    {
      name: "Що таке AP Education",
      href: "/about-us/shcho-take-ap-education/",
    },
    { name: "Ваша кар’єра з нами", href: "/about-us/kariera/" },
    { name: "Стань партнером", href: "/about-us/stan-partnerom/" },
    { name: "Запрошуємо до співпраці", href: "/about-us/spivpratsia/" },
  ],
};

export interface FooterCustomProps {
  color?: "violet" | "green" | "blue";
  srcLogo?: string;
  socialLinks?: {
    facebook?: string;
    instagram?: string;
    tiktok?: string;
    linkedin?: string;
    youtube?: string;
  };
}

export default function FooterCustom({
  color,
  socialLinks,
  srcLogo = "/assets/icon/FooterEducationLogo.svg",
}: FooterCustomProps) {
  return (
    <footer id="footer" data-meta="FooterCustom" className={`text-white`}>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8  pb-12 border-b">
          <div className="space-y-8">
            <Image
              width={300}
              height={80}
              alt=""
              src={srcLogo}
              className=""
            />
            <Image
              width={175}
              height={80}
              alt=""
              src={"/assets/icon/MONWhite.svg"}
              className=""
            />
            <div>
              <div className="flex items-center mt-12">
                <Image
                  src="/assets/icon/vuesax/location.png"
                  alt=""
                  width={20}
                  height={20}
                />
                <a
                  href="https://maps.app.goo.gl/HxoxpCMGcKujWfzB8"
                  className="ml-2 text-white"
                  target="_blank"
                >
                  м. Львів, вул. Богдана Хмельницького, 114а
                </a>
              </div>
              <div className="flex items-center mt-2">
                <Image
                  src="/assets/icon/vuesax/call.png"
                  alt=""
                  width={20}
                  height={20}
                />
                <a href="tel:+380936707129" className="ml-2 text-white">
                  +38 (093) 670 71 29
                </a>
              </div>
              <div className="flex items-center mt-2">
                <Image
                  src="/assets/icon/vuesax/sms.png"
                  alt=""
                  width={20}
                  height={20}
                />
                <a href="mailto:info@ap.education" className="ml-2 text-white">
                  info@ap.education
                </a>
              </div>
            </div>
            <div className="flex space-x-6">
              <div className="flex gap-3 mt-8">
                <div
                  className={`h-fit space-y-4 text-base leading-7 text-gray-600  rounded-full flex justify-center items-center`}
                >
                  <Link
                    href={
                      socialLinks?.facebook ??
                      "https://www.facebook.com/ap.edu.centre/"
                    }
                    className="border border-white rounded-full"
                  >
                    <Image
                      width={20}
                      height={20}
                      className="h-8 w-8 p-1 m-1"
                      src="/assets/icon/FBSolid.svg"
                      alt=" "
                    />
                  </Link>
                </div>
                <div
                  className={`h-fit space-y-4 text-base leading-7 text-gray-600 rounded-full flex justify-center items-center`}
                >
                  <Link
                    href={
                      socialLinks?.tiktok ??
                      "https://www.tiktok.com/@ap.education.center?_t=8qLAl2a7AUH&_r=1"
                    }
                    className="border border-white rounded-full"
                  >
                    <Image
                      width={20}
                      height={20}
                      className="h-8 w-8 p-1 m-1"
                      src="/assets/icon/TKSolid.svg"
                      alt=""
                    />
                  </Link>
                </div>
                <div
                  className={`h-fit space-y-4 text-base leading-7 text-gray-600 rounded-full flex justify-center items-center`}
                >
                  <Link
                    href={
                      socialLinks?.instagram ??
                      "https://www.instagram.com/ap.education"
                    }
                    className="border border-white rounded-full"
                  >
                    <Image
                      width={20}
                      height={20}
                      className="h-8 w-8 p-1 m-1"
                      src="/assets/icon/InstSolid.svg"
                      alt=" "
                    />
                  </Link>
                </div>
                <div
                  className={`h-fit space-y-4 text-base leading-7 text-gray-600  rounded-full flex justify-center items-center`}
                >
                  <Link
                    href={
                      socialLinks?.linkedin ??
                      "https://www.linkedin.com/company/ap-education-center"
                    }
                    className="border border-white rounded-full"
                  >
                    <Image
                      width={20}
                      height={20}
                      className="h-8 w-8 p-1 m-1"
                      src="/assets/icon/LinkSolid.svg"
                      alt=""
                    />
                  </Link>
                </div>
                <div
                  className={`h-fit space-y-4 text-base leading-7 text-gray-600 rounded-full flex justify-center items-center`}
                >
                  <Link
                    href={
                      socialLinks?.youtube ??
                      "https://www.youtube.com/@ap.education.center"
                    }
                    className="border border-white rounded-full"
                  >
                    <Image
                      width={20}
                      height={20}
                      className="h-8 w-8 p-1 m-1"
                      src="/assets/icon/YTSolid.svg"
                      alt=" "
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 lg:col-span-2 lg:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <p className="text-sm font-semibold leading-6 text-white hover:text-gray-600">
                  <Link href="/school">AP School</Link>
                </p>
                <ul className="mt-6 space-y-4">
                  {navigation.ap_school.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-white hover:text-gray-600 "
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <p className="text-sm font-semibold leading-6 text-white">
                  <Link href="/university">AP University</Link>
                </p>
                <ul className="mt-6 space-y-4">
                  {navigation.ap_university.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-white hover:text-gray-600"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <p className="text-sm font-semibold leading-6 text-white">
                  <Link href={"/movni-kursy"}>МОВНІ КУРСИ</Link>
                </p>
                <ul className="mt-6 space-y-4">
                  {navigation.language.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-white hover:text-gray-300"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <p className="text-sm font-semibold leading-6 text-white">
                  <Link href={"/about-us"}>ПРО НАС</Link>
                </p>
                <ul className="mt-6 space-y-4">
                  {navigation.about.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-white hover:text-gray-300"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                  <li>
                    <p className="text-sm font-semibold leading-6 text-white">
                      <Link href={"/bloh"}> БЛОГ</Link>
                    </p>
                    <p className="text-sm leading-6 text-white hover:text-gray-300">
                      Новини, статті, подкасти з гостями в блозі
                    </p>
                    <Link
                      href={"/bloh"}
                      className="flex gap-x-4 justify-between mt-4 items-center rounded-full pl-4 pr-1 py-1 font-semibold border text-white shadow-lg ring-t-1"
                    >
                      <span className="py-1 lg:text-xs xl:text-base uppercase">
                        Блог
                      </span>
                      <div className={`rounded-full bg-white`}>
                        <ArrowUpRightIcon className="text-green-600 lg:h-[16px] lg:w-[16px] xl:h-[20px] xl:w-[20px] m-[4px] xl:m-[6px]" />
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 border-t border-gray-900/10 pt-8 sm:mt-8 lg:mt-8">
          <div className="flex justify-between items-center max-sm:flex-col">
            <div className="text-xs leading-5 text-white">
              Copyright © 2024 AP Education Center | All Rights Reserved |
              <Link
                href={"https://ap.education/static/docs/privacy_policy.docx"}
              >
                Політика конфіденційності
              </Link>{" "}
              |{" "}
              <Link href={"https://ap.education/static/docs/public_offer.docx"}>
                Публічна оферта
              </Link>
            </div>
            <div className="flex items-center">
              <Image
                width={20}
                height={20}
                className="h-20 w-20 p-1 m-1"
                src="/assets/icon/payment.svg"
                alt=""
              />
              <Image
                width={20}
                height={20}
                className="h-12 w-12 p-1 m-1"
                src="/assets/icon/PUMB.svg"
                alt=""
              />
              <Image
                width={20}
                height={20}
                className="h-12 w-12 p-1 m-1"
                src="/assets/icon/part_payment.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
